import { Box, Button, Card, CardHeader, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Image, Input, Link, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Text, Textarea, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import Header from '../components/Header'
import FooterItem from '../components/FooterItem'
import { useEffect, useRef } from "react";
import { ECHANNELLING, ApiEndpoints } from "../constants/ApiCunstants";
import { Methods } from "../constants";
import { useForm } from 'react-hook-form';
import { MdCheckCircle, MdStar } from 'react-icons/md';
import { Link as RouterLink } from "react-router-dom";

function Prescriptions() {

  const videoRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [name, setName] = useState(null);
  const [dob, setDob] = useState(null);
  const [email, setEmail] = useState(null);
  const [docname, setDocName] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [note, setNote] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModalIndex, setSelectedModalIndex] = useState(null);

  const onOpen = (index) => {
    setIsOpen(true);
    setSelectedModalIndex(index);
  };

  const onClose = () => {
    setIsOpen(false);
    setSelectedModalIndex(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {

      sendmail();

      reset(); // Clear form fields
    } catch (error) {
      // Display error toast
      // toast({
      //   title: 'Error',
      //   description: 'An error occurred. Please try again later.',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    }

    setLoading(false);
  };

  const sendmail = () => {
    // setIsLoading(true);
    // console.log(selectedDate);
    // console.log(spId);
    // console.log(doctorName);
    try {

      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.echannelling.sendmailPrescription;

       // Create FormData object
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("telephone", telephone);
        formData.append("docname", docname);
        formData.append("note", note);

        // Append file if available
        if (file) {
          formData.append("prescription", file);
        }


      fetch(URL, {
        method: Methods.POST,
        body: formData,
      })
        .then((response) => response.json())
        .then((object) => {
          if (object) {

            console.log(object);
            if (!object.error) {
              toast({
                title: 'Success',
                description: object.msg,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
             // window.location.reload();
            } else {
              toast({
                title: 'Error',
                description: object.msg,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      //setIsLoading(false);
      setTelephone('');
      setEmail('');
      setName('');
      setDob('');
      setDocName('');
      setNote('');
      

    }
  };

  const handleClick = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
      thumbnailRef.current.style.display = "none";
    }
  }, [isPlaying]);

  const [file, setFile] = useState(null);

  return (
    <div>
      <Box>
        <Header/>
        {/* <Heading mt={10} ml={10}>About Us</Heading> */}
        <Box
        backgroundImage={require('../img/pagecovers/cutting-edge-strip.png')}
        backgroundSize="cover"
        backgroundPosition="center"
        height="200px"
        display="flex"
        mt="10px"
        alignItems="center"
  // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          fontWeight="bold"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Upload Prescriptions
        </Text>
      </Box>

      <Grid templateColumns={['1fr', '1fr', '2fr 2fr', '2fr 2fr']} flexDirection={['row', 'column']} pl={['5','5']} pr={['5','5']}>
        <Box width={'100%'} p={['5','8']}>
        Easily upload your medical prescriptions online for hassle-free processing at Nawaloka Hospitals. 
        Our secure system ensures quick access to medications and seamless coordination with our pharmacy. 
        Save time and get your prescriptions ready with just a few clicks!

        <Card width="100%" p="10px" boxShadow={'2xl'} mt="20px" mb="10px" backgroundColor="#4aa7b0">
              <CardHeader><Heading size="md" color="white">Contact Details</Heading></CardHeader>
                <VStack spacing={0} mb="10px" alignItems="center">
                    <Text fontSize="lg" fontWeight={'semibold'} color="whiteAlpha.800" whiteSpace={'pre-line'}>
                    Direct Contact: 0115 777 777{'\n'}
                    </Text>
                </VStack>   
            </Card>
   
        </Box>

        <Box width={'100%'} padding={['2','2']} mt={['2','0','5']} order={[1, 2]}>
            
              <Card width="100%" p="10px" boxShadow={'2xl'} mt="10px" mb="10px" backgroundColor="#4aa7b0">
              <CardHeader><Heading size="lg" color="white">Upload Prescriptions</Heading></CardHeader>
              <Box width="90%" mx="auto">
                <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl id="type" mb={4} isRequired>
                  <FormLabel>Prescription Type</FormLabel>
                  <Select
                  >
                    <option value="#">Select Type</option>
                    <option value="#">CT Scan</option>
                    <option value="#">MRI Scan</option>
                    <option value="#">Pharmacy Prescription</option>
                    <option value="#">Lab Prescription</option>
                  </Select>
                </FormControl>
                  <FormControl id="name" mb={4} isRequired>
                    <FormLabel>Patient Name</FormLabel>
                    <Input required type="text" {...register('name')} bg="white" value={name} onChange={(event) => setName(event.target.value)} />
                  </FormControl>

                  <FormControl id="telephone" mb={4} isRequired>
                    <FormLabel>Telephone</FormLabel>
                    <Input required value={telephone} {...register('telephone')} bg="white" onChange={(event) => setTelephone(event.target.value)} type="number" />
                  </FormControl>

                  <FormControl id="note" mb={4}>
                    <FormLabel>Note</FormLabel>
                    <Textarea {...register('note')} bg="white" defaultValue={note} onChange={(event) => setNote(event.target.value)} resize="vertical" />
                  </FormControl>

                  {/* File Upload */}
                  <FormControl id="prescription" mb={4} isRequired>
                    <FormLabel>Upload Prescription</FormLabel>
                    <Input type="file" accept="image/*,application/pdf" onChange={(event) => setFile(event.target.files[0])} bg="white" p={2} />
                  </FormControl>

                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    bg="#2A3C24"
                    color="white"
                    mt={4}
                    mb={4}
                    _hover={{
                      bg: '#243010',
                      color: 'white',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    Reserve
                  </Button>
                </form>
              </Box>

            </Card>
        </Box>
      </Grid>

        <FooterItem/>
        </Box>
    </div>
  )
}

export default Prescriptions
