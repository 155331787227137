import React, { useState } from "react";
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react";
  

const CTComponent = () => {

  
  const [selectedCTScan, setSelectedCTScan] = useState("");


  const handleSelectCTScan = (event) => {
    setSelectedCTScan(event.target.value);
  };

   const renderCTScanForm = () => {
      switch (selectedCTScan) {
        case "AbdomenPelvis":
          return <Box mt="20px">AbdomenPelvis form here</Box>;
        case "Ankel":
          return <Box mt="20px">Ankel form here</Box>;
        case "CalciumScore":
          return <Box mt="20px">CalciumScore form here</Box>;
        default:
          return null;
      }
    };


  return (
    <Box>
    <FormControl mb={[2, 4]} mt={[2, 4]}>
      <FormLabel>Which body part requires this service?</FormLabel>
      <Select
       value={selectedCTScan}
       onChange={handleSelectCTScan}>
        <option value="#">Please Select</option>
        <option value="AbdomenPelvis">Abdomen & Pelvis</option>
        <option value="Ankel">Ankel/s</option>
        <option value="CalciumScore">Calcium Score</option>
        <option value="CardiacAngiogram">Cardiac Angiogram</option>
        <option value="Chest">Chest</option> 
        <option value="ChestAdbomenPelvis">Chest, AdbomenPelvis</option>
      </Select>
      {renderCTScanForm()}
    </FormControl>
    </Box>
  );
  };
  
  export default CTComponent;