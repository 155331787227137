import React from 'react';
import { ReactNode } from 'react';

import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';


const Logo = (props: any) => {
  return (
    <img height={52} width={52} src={require('../img/NawalokaPLC.png')} alt="Nawaloka Logo" />
   
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function FooterItem() {
  return (
    <Box 
    mt="5px"
      bgGradient="linear(to-r, #003366, #FF5722)"
      color="white">
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr', md: '2fr 1fr 1fr 1fr 2fr' }}
          spacing={8}>
          <Stack spacing={6}>
          <Flex alignItems="center" mt="5px">
            <Logo color={useColorModeValue('gray.700', 'white')} />
            <Text fontSize={'lg'} ml={2} position="relative" top="-5px">
              Nawaloka Hospitals PLC
            </Text>
          </Flex>
            <Text fontSize={'xs'}>
              © 2025 Nawaloka Hospitals PLC. All rights reserved
            </Text>
          </Stack>
          <Stack></Stack>
          <Stack></Stack>
          <Stack></Stack>
          <Stack fontSize={'xs'} lineHeight={'1.2em'} align={'flex-start'}>
            <ListHeader>Quick Links</ListHeader>
            <Link href={'/aboutus'}>About Us</Link>
            <Link href={'/international'}>International Patients</Link>
            <Link href={'/emergency'}>Emergency Care</Link>
            <Link href={'/channeling'}>Doctor Channeling</Link>
            <Link href={'/contactus'}>Contact us</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}