import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import sagas from "./store/sagas";
import reducers from "./store/reducers";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LandingPage from "./pages/LandingPage.tsx";
import Emergency from "./pages/Emergency";
import Virtual from "./pages/Virtual";
import Channeling from "./pages/Channeling";
import Doorstep from "./pages/Doorstep";
import AboutUs from "./pages/aboutuspages/AboutUs";
import International from "./pages/International";
import ContactUs from "./pages/ContactUs";
import DoctorSearch from "./pages/DoctorSearch";
import DoctorProfile from "./pages/DoctorProfile";
import ServicePage from "./pages/ServicePage";
import HeartCentrePage from "./pages/servicepages/HeartCentre"
import Surgery from "./pages/Surgery";
import BookSession from "./pages/BookSession";
import BookingSummery from "./pages/BookingSummery";
import NeuroCarePage from "./pages/servicepages/NeuroCarePage"
// This is the way to import an SVG file and then pass it as a props
// import { ReactComponent as CompanyIcon } from './assets/crown.svg';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import Preloader from './components/Preloader';
import { useEffect, useState } from "react";
import RnSPage from "./pages/servicepages/RnSPage";
import Awards from "./pages/aboutuspages/Awards.tsx"
import { useSelector } from 'react-redux';
import { loadingSelector } from './store/WebStore/selectors';
import Entry from "./Entry";
import Technology from "./pages/aboutuspages/Technology";
import Consultants from "./pages/aboutuspages/Consultants";
import ChairmansMessage from "./pages/aboutuspages/ChairmansMessage";
import HealthChecks from "./pages/healthchecks/HealthChecks";
import PackageDetails from "./pages/healthchecks/PackageDetails";
import Cart from "./pages/healthchecks/Cart";
import { Spinner } from "@chakra-ui/spinner";
import FertilityCare from "./pages/servicepages/FertilityCare";
import Radiology from "./pages/servicepages/Radiology";
import Access from "./pages/access";
import PhysioForm from "./pages/PhysioForm";
import Physiotherapy from "./pages/clinics/Physiotherapy";
import Media from "./pages/Media";
import Careers from "./pages/Careers";
import EyeCare from "./pages/clinics/EyeCare";
import SearchJobs from "./pages/careers/SearchJobs";
import AdmResev from "./pages/AdmResev";
import Checkout from "./pages/healthchecks/Checkout";
import MedDelivery from "./pages/MedDelivery";
import PaymentSummery from "./pages/healthchecks/PaymentSummery";
import SuccessPage from "./pages/healthchecks/SuccessPage";
import Facilities from "./pages/aboutuspages/Facilities";
import Reports from "./pages/aboutuspages/Reports";
import Discounts from "./pages/Discounts";
import RoomPage from "./components/RoomPage";
import Room01Page from "./pages/aboutuspages/Room01Page";
import LabMob from "./pages/LabMob";
import BloodBank from "./pages/clinics/BloodBank";
import Promotions from "./pages/media/Promotions";
import MomAndBaby from "./pages/servicepages/MomAndBaby";
import Cosmetic from "./pages/servicepages/Cosmetic";
import Dental from "./pages/servicepages/Dental";
import Diabetes from "./pages/servicepages/Diabetes";
import KissPromo from "./pages/KissPromo";
import CancerCare from "./pages/servicepages/CancerCare";
import Nutrition from "./pages/clinics/Nutrition";
import OnlinePayments from "./pages/healthchecks/OnlinePayments";
import WebxLink from "./pages/healthchecks/WebxLink";
import EliteCentre from "./pages/clinics/EliteCentre";
import OnlinePaymentDetails from "./pages/healthchecks/OnlinePaymentDetails";
import MothersDay from "./pages/other/MothersDay";
import VoucherDetails from "./components/VoucherDetails";
import VoucherPayments from "./pages/other/VoucherPayments";
import EmailTemp from "./pages/other/EmailTemp";
import FailedPage from "./pages/healthchecks/FailedPage";
import Laboratory from "./pages/Laboratary";
import HeartCentre from "./pages/servicepages/HeartCentre";
import ChatbotWidget from './components/ChatbotWidget';
import ChatGPTBot from './components/ChatGPTBot';
import Prescriptions from "./pages/Prescriptions";

const sagaMiddleware = createSagaMiddleware(),
  store = createStore(reducers, applyMiddleware(sagaMiddleware));

function App() {


  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   // Simulating a delay of 2 seconds for demonstration purposes
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  // if (isLoading) {
  //   return <Spinner colorScheme="teal" size={'lg'} justifyContent={'center'} alignContent={'center'} />;
  // } 

  // Google Tag Manager code
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-E42ZP40RPT';
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-E42ZP40RPT');
  };

  return (

    <Provider store={store}>
    <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/emergency" element={<Emergency />} />
            <Route path="/virtual" element={<Virtual />} />
            <Route path="/channeling" element={<Channeling />} />
            <Route path="/doorstep" element={<Doorstep />} />
            {/* <Route path="/access" element={<Access />} /> */}
            
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/aboutus/message" element={<ChairmansMessage />} />
            <Route path="/aboutus/awards" element={<Awards />} />

            <Route path="/aboutus/technology" element={<Technology />} />
            <Route path="/aboutus/facilities" element={<Facilities />} />
            <Route path="/aboutus/rooms/" element={<RoomPage />} />
            <Route path="/aboutus/rooms/:roomNumber" element={<Room01Page />} />
            
            <Route path="/aboutus/consultants" element={<Consultants />} />
            <Route path="/aboutus/reports" element={<Reports />} />

            <Route path="/international" element={<International />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/doctorsearch" element={<DoctorSearch />} />
            <Route path="/doctorprofile" element={<DoctorProfile />} />

            <Route path="/servicepage" element={<ServicePage />} />
            <Route path="/heart-centre" element={<HeartCentre />} />
            <Route path="/servicepages/neurocare" element={<NeuroCarePage />} />
            <Route path="/servicepages/rns" element={<RnSPage />} />
            <Route path="/servicepages/fertilitycare" element={<FertilityCare />} />
            <Route path="/radiology" element={<Radiology />} />
            <Route path="/servicepages/momandbaby" element={<MomAndBaby />} />
            <Route path="/servicepages/cosmetic" element={<Cosmetic />} />
            <Route path="/servicepages/dental" element={<Dental />} />
            <Route path="/servicepages/diabetes" element={<Diabetes />} />
            <Route path="/servicepages/cancer" element={<CancerCare />} />

            {/* Clinic Pages  */}
            <Route path="/clinics/physiotherapy" element={<Physiotherapy />} />
            <Route path="/clinics/eyecare" element={<EyeCare />} />
            <Route path="/clinics/bloodbank" element={<BloodBank />} />
            <Route path="/clinics/nutrition" element={<Nutrition />} />
            <Route path="/elitecentre" element={<EliteCentre />} />

            {/* <Route path="/about" element={<About />} />

        <Route path="/contact" element={<Contact />} /> */}

            {/* Health Checks Pages  */}
            <Route path="/healthchecks" element={<HealthChecks />} />
            <Route path="/healthchecks/package-details" element={<PackageDetails />} />
            <Route path="/healthchecks/cart" element={<Cart />} />
            <Route path="/healthchecks/checkout" element={<Checkout />} />
            <Route path="/healthchecks/summery" element={<PaymentSummery />} />
            <Route path="/healthchecks/success" element={<SuccessPage />} />
            <Route path="/healthchecks/failed" element={<FailedPage />} />

            <Route path="/payments" element={<OnlinePaymentDetails />} />
            <Route path="/any-payment" element={<WebxLink />} />

            {/* <Route path="/voucher" element={<MothersDay />} />
            <Route path="/voucher/pay" element={<VoucherPayments />} /> */}
            {/* <Route path="/emailtemp" element={<EmailTemp/>} /> */}



            {/* <Route path="/header" element={<Header />} />
        <Route path="/navbar" element={<Navbar />} /> */}

            {/* Treatments */}
            <Route path="/treatments/surgery" element={<Surgery />} />
            {/* <Route path="/doctorprofile" element={<DoctorProfile />} /> */}

            <Route path="/booksession" element={<BookSession />} />
            <Route path="/bookingsummery" element={<BookingSummery />} />
            <Route path="/physiofrom" element={<PhysioForm />} />
            <Route path="/admresev" element={<AdmResev />} />
            {/* <Route path="/meddelivery" element={<MedDelivery />} /> */}
            <Route path="/labmob" element={<LabMob />} />
            <Route path="/laboratory" element={<Laboratory />} />
            {/* <Route path="/upload-prescriptions" element={<Prescriptions />} /> */}


            <Route path="/media" element={<Media />} />
            <Route path="/promotions" element={<Promotions />} />

            <Route path="/careers/searchjobs" element={<SearchJobs />} />
            
            {/* <Route path="/discounts/anniversary-discounts" element={<Discounts />} /> */}

        </Routes>
        
        <WhatsAppWidget phoneNumber="+94115777777" companyName="Nawaloka Hospitals PLC"/>
      </Router>
    </Provider>

  );

}

sagaMiddleware.run(sagas);

export default App;

export { store };
