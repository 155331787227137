import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FooterItem from "../../components/FooterItem";
import surgeryIcon2 from "../../img/healthcheck2.jpeg";
import cardImg from "../../img/card-img.jpg";
import { useNavigate } from "react-router-dom";
import { ECHANNELLING, ApiEndpoints } from "../../constants/ApiCunstants";
import { Methods } from "../../constants";
import { useLocation } from "react-router-dom";

function OnlinePayments() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [total, setTotal] = useState(0.0);
  const [items, setItems] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    loadItem();
  }, []);

  const loadItem = () => {
    console.log(sessionStorage.getItem("sessionId"));
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.getCart;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            setItems(object.data.data);
            setTotal(object.data.sub_total);
            //console.log(object.data);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
  };

  const proceedPayment = () => {
    try {
      const method = Methods.POST;
      const URL = ECHANNELLING.BASE_URL + ApiEndpoints.healthPackages.checkout;
      fetch(URL, {
        method,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: JSON.stringify({
          session_id: sessionStorage.getItem("sessionId"),
          mobile: mobile,
          email: email,
          name: name,
          address: address,
          total: total,
        }),
      })
        .then((response) => response.json())
        .then((object) => {
          if (object.error == false) {
            // window.location.reload();
            console.log(object);
          } else {
            console.log(object);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
    } finally {
    }
    navigate("/healthchecks/success");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic here
    // You can send the form data to a backend API or perform any other required action
    console.log("Form submitted:", {
      //   name,
      //   nic,
      //   phoneNumber,
      //   date,
      //   email,
      //   time,
    });
  };

  const [selectedOption, setSelectedOption] = useState('');

  // Function to handle change in select option
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

   // Function to render different forms based on selected option
   const renderForm = () => {
    switch (selectedOption) {
      case 'In-patient payment':
        return <Box mt="20px">
           <FormControl mb="10px">
                <FormLabel>Patient BHT</FormLabel>
                <Input
                  type="text"
                 value={searchParams.get("merchantCustomerPhone")}
                  placeholder="Enter Patient BHT"
                  onChange={(e) => setMobile(e.target.value)}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </FormControl>

              
              <FormControl mb="10px" mt="20px">
                <Button colorScheme="teal" type="submit">
                      Continue
                    </Button>
              </FormControl>


        </Box>;
      case 'Other payment':
        return <Box mt="20px">Coming Soon...</Box>;
      default:
        return null;
    }
  };



  return (
    <Box>
      <Header />
      <Box
        backgroundImage={require("../../img/WebsiteStrip1.png")}
        backgroundSize="cover"
        backgroundPosition="center"
        height="120px"
        display="flex"
        mt="10px"
        alignItems="center"
        // justifyContent="center"
      >
        <Text
          fontSize="4xl"
          ml="40px"
          color="white"
          textShadow="0 2px 4px rgba(0,0,0,0.5)"
        >
          Online Bill Payment
        </Text>
      </Box>

      <Box p={4} bg="gray.100" borderRadius="md" shadow="md">
        <Grid gap={4} templateColumns={["1fr", "1fr", "1fr"]} pl="300px" pr="300px">
          <Card>
            {/* <Heading
              mb="10px"
              pl="20px"
              pt="10px"
              fontSize="24px"
              color="#F8D107"
            >
              Billing Details
            </Heading> */}
            <Box mx="auto" width="100%" pr={6} pl={6}>
              {/* <form onSubmit={handleSubmit}> */}

              {/* <FormControl mb="10px">
                <FormLabel>Name</FormLabel>
                <Flex>
                  <Input
                    mt="5px"
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                    required
                    disabled
                  />
                </Flex>
              </FormControl> */}

              {/* <FormControl mb="10px">
                <FormLabel>Address</FormLabel>
                <Textarea
                  // value={value}
                  // onChange={handleInputChange}
                  placeholder="Address"
                  size="sm"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl> */}
             

             <FormControl mb="30px" mt="10px">
                <FormLabel>Payment Type</FormLabel>
                <Select
                  placeholder='Select payment type'
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="In-patient payment">In-patient payment</option>
                  <option value="Other payment">Other payment</option>
                </Select>
                {/* Render different forms based on selected option */}
                {renderForm()}
              </FormControl>


              {/* </form> */}
            </Box>
          </Card>
        </Grid>
      </Box>
      <FooterItem />
    </Box>
  );
}

export default OnlinePayments;
